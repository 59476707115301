<template>
  <div class="HomePage">
    <div class="HomePage_herd">
      <headassembly></headassembly>
    </div>
      <div class="div1">
         <img src="../assets/image/shang.jpg" v-show="!showfalg" @click="close">
         <img src="../assets/image/xia.jpg" v-show="showfalg" @click="open">
      </div>
    <div class="HomePage_body">
      <div class="HomePage_body1">
        <div class="HomePage_body1_lower" :class="{HomePage_body1_lower1:showfalg==true}" v-show="!showfalg">
          <h2 class="HomePage_body1_h2">
            <p>
              <span></span>
              <span>项目概览</span>
            </p>
             <p @click="Seemore" style="cursor:pointer;">
              <span>查看更多</span>
              <span>></span>
            </p>
          </h2>
          <div class="HomePage_body1_div1">
            <div class="div1">
              <h2 id="mainA"></h2>
            </div>
            <div class="div2">
              <h2 id="mainB"></h2>
            </div>
          </div>
          <div class="HomePage_body1_div2">
            <ul>
              <li>
                <h2>
                  <span>项目总数</span>
                </h2>
                <p>
                  <span>{{ obj.allNum }}</span>
                </p>
              </li>
              <li>
                <h2>
                  <span>已完成</span>
                </h2>
                <p>
                  <span>{{ obj.finishNum }}</span>
                </p>
              </li>
              <li>
                <h2>
                  <span>待完成</span>
                </h2>
                <p>
                  <span>{{ obj.unfinishedNum }}</span>
                </p>
              </li>
              <li>
                <h2>
                  <span>延期</span>
                </h2>
                <p>
                  <span>{{ obj.delayedNum }}</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div class="HomePage_body1_upper" @scroll="Scroll" :class="{HomePage_body1_upper1:showfalg==true}">
          <h2>
            <p>
              <span></span>
              <span>项目</span>
            </p>
            <p @click="alltopic">
              <span>查看全部</span>
              <span>></span>
            </p>
          </h2>
          <div class="div4" v-for="(item, index) in projectlist" :key="index">
            <div class="div5">
              <h3>
                <p>
                  <img src="../assets/image/t1.png" /><span>{{
                    item.TopicName | ellipsis
                  }}</span>
                  <span v-show="item.IsCheck == 1">
<!--                    <a>-->
                    {{ item.CheckText }}
<!--                  </a>-->
                  </span
                  >
                </p>
                <p>
                  <img src="../assets/image/t2.png" /><span
                    >负责人:&nbsp;{{ item.Principal }}</span
                  ><img src="../assets/image/t3.png" /><span
                    >起始时间:&nbsp;{{ item.StartDate.substr(0, 10) }}~{{
                      item.EndDate.substr(0, 10)
                    }}</span
                  >
                </p>
              </h3>
              <h4>
                <button @click="Gotooperation(item)" style="cursor:pointer;">前往操作 <span style="color:#3388FF;font-size: 20px;vertical-align: middle;">></span></button>
              </h4>
            </div>
            <div class="div6">
              <div class="div6_left">
                <el-progress
                  type="circle"
                  :percentage="item.sop"
                  :stroke-width="18"
                  :width="200"
                ></el-progress>
                <p>项目进度</p>
              </div>
              <div class="div6_right">
                <div class="div6_right_1">
                  <el-steps
                    :space="200"
                    :active="item.active"
                    finish-status="success"
                    :align-center="true"
                  >
                    <el-step
                      :title="row.text"
                      v-for="(row, i) in item.flowNameJoint"
                      :key="i"
                    >
                    </el-step>
                  </el-steps>
                </div>
                <div class="div6_right_2">
                  <p v-for="(m, n) in item.list" :key="n">
                    <span></span>
                    <span>{{ m.UserName }}{{ m.LogText }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="HomePage_body2">
        <div class="HomePage_body2_lower">
          <h3>
            <p>
              <span></span>
              <span>通知通告</span>
            </p>
            <p @click="msg1">
              <span>查看更多</span>
              <span>></span>
            </p>
          </h3>
          <div class="div7">
            <ul>
              <el-tooltip
                v-for="(item, index) in msglist"
                :key="index"
                placement="top"
              >
                <div slot="content">
                  <p class="p1" style="width: 300px">
                    {{ item.ReceiveUserName }}{{ item.MsgText
                    }}{{ item.CreateDate }}
                  </p>
                </div>
                <li>
                  <img src="../assets/image/xiaolaba.png" />
                  <span>{{ item.ReceiveUserName }}</span>
                  <span>{{ item.MsgText }}</span>
                  <span>{{ item.CreateDate }}</span>
                </li>
              </el-tooltip>
            </ul>
          </div>
        </div>
        <div class="HomePage_body2_upper">
          <h3>
            <p>
              <span></span>
              <span>阶段统计</span>
            </p>
            <p @click="alltopic">
              <span>查看项目</span>
              <span>></span>
            </p>
          </h3>
          <div class="div3">
            <h2 id="mainC"></h2>
          </div>
        </div>
      </div>
    </div>
    <guidance-chart :tips="tips" @tips-child="tipsChild"></guidance-chart>
    <div class="notice-list">
      <el-dialog :visible.sync="dialogVisible">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">通知通告列表</span>
        </div>
        <!--        <template>-->
        <div style="height: 400px" class="content">
          <el-table
            :data="tableData"
            max-height="400px"
            style="width: 100%"
            :header-cell-style="{
                color: '#333333',
                backgroundColor: '#FBFBFD',
              }"
            row-key="id"
            border
          >
            <el-table-column prop="SendUserName" label="创建人" width="120" align="center">
            </el-table-column>
            <el-table-column prop="MsgName" label="标题" width="180" align="center">
            </el-table-column>
            <el-table-column prop="CreateDate" label="发送时间" width="200" align="center">
            </el-table-column>
            <el-table-column prop="LookFlag" label="是否已查看" align="center">
            </el-table-column>
            <el-table-column
              label="下载附件"
              align="center"
              width="120">
              <template slot-scope="scope">
                <span @click="seeFile(scope.row)"  style="color: #3978E7;font-weight: 400" v-show="scope.row.TopicId == 0">附件</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-link
                  style="font-size:16px;color: #3978E7;font-weight: 400"
                  type="text"
                  :underline="false"
                  @click="handleEdit(scope.$index, scope.row)"
                ><i class="el-icon-view"></i>查看</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--        </template>-->
        <div class="block">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="num"
            :page-size="13"
            layout="total,prev, pager, next, jumper"
            :total="PageCount">
          </el-pagination>
        </div>
      </el-dialog>
    </div>
    <div class="notice-details">
      <el-dialog
        top="25vh"
        :visible.sync="handleEditDialog"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">消息详情</span>
        </div>
        <el-form  label-width="90px" :model="tableDetailed">
          <el-form-item label="标题:">
            <el-input type="textarea" v-model="tableDetailed.MsgName" readonly ></el-input>
          </el-form-item>
          <el-form-item label="创建人:">
            <el-input v-model="tableDetailed.SendUserName" readonly style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item label="接收人:">
            <el-input v-model="tableDetailed.ReceiveUserName" readonly ></el-input>
          </el-form-item>
          <el-form-item label="创建时间:">
            <el-input v-model="tableDetailed.CreateDate" readonly style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item label="内容:">
            <el-input type="textarea" v-model="tableDetailed.MsgText" readonly ></el-input>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import api from "../api/url";
import apis from "../api/Grantsandmilestones"
import headassembly from "../components/headassembly.vue";
import reseacrh from "@/api/research";
import research from "@/api/research";
import {downloadFileId, NoticeFileId} from "@/utils/downloadUrl";
export default {
  data() {
    return {
      statisticaNum: 0,
      tips: {
        name: "homePage",
        url: require('../assets/image/tips/homePage.png')
      },
      PageCount: 0,
      currentPage: 1, // 分页参数
      tableDetailed: {},
      handleEditDialog: false,
      //是否显示设置信息
      val: 0,
      message: false,
      dialogVisible: false,
      tableData: [],
      num: 1,
      editPasswordDialog:false,
      showfalg:false,
      show3: true,
      name: "姓名姓名姓名姓名姓名姓名姓名名姓名",
      obj: {},
      list: [],
      list1: [],
      msglist: [],
      projectlist: [],
      flag: false,
      TotalPages: 0,
      TotalRowsCount: 0,
      i: 0,
      arrx:[]
    };
  },
  created() {
    this.sendoutmsglist();
    this.Stopiclist();
    this.$store.commit("nodekeydata", 1);
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 44) {
        return value.slice(0, 44) + "...";
      }
      return value;
    },
  },
  components: {
    headassembly,
  },
  mounted() {
    this.checkProjectOverviewlist();
    this.Completionlist();
  },
  methods: {
    Seemore(){//项目概览查看更多
     this.$router.push({ name: "Allstatistics" });
    },
    tipsChild(data) {
      localStorage.setItem(this.tips.name,data)
    },
    msg1() {
      this.dialogVisible = true;
      this.msg()
    },
    msg() {
      var queobj = {
        TopicId: "",
        PageIndex: this.num-1,
        PageSize: 13,
        SysFlag:-1
      };
      research.querymsg(queobj).then((res) => {
        console.log(res,'分页')
        res.data.Entity.forEach((item) => {
          if (item.LookFlag == 0) {
            item.LookFlag = "未查看";
          }
          if (item.LookFlag == 1) {
            item.LookFlag = "已查看";
          }
        });

        this.tableData = res.data.Entity;
        this.PageCount = res.data.TotalRowsCount
      });
    },
    handleCurrentChange(val){ // 分页
      this.num=val
      this.msg()
      console.log(`当前页: ${val}`);
    },
    seeFile(item) { // 查看附件  除图片除外
      reseacrh.preFinancFile(item.DataGuid).then(res=>{
        console.log(res)
        if (res.data.Status==1) {
          if (res.data.ExtraData.length == 0) {
            this.$message.warning('该通知公告没有上传附件')
            return false
          }
          res.data.ExtraData.forEach( items => {
            this.downloadfile(items.Id,item.Id)
          })
        }
      })
    },
    handleEdit(i, item) {
      this.tableDetailed = item
      research.getmsg(item.Id).then((res) => {
        if (res.data.Status == 1) {
          research.nummsg().then((res) => {
            this.val = res.data;
            this.msg()
            console.log(res.data, "消息提醒");
          });
          this.handleEditDialog = true
        }
      });
    },
    downloadfile(DataId,MessageId){
      NoticeFileId(DataId,MessageId)
    },
    close(){
     this.showfalg=true
    },
    open(){
    this.showfalg=false
    },
    Gotooperation(row) {
      console.log(row);
      //前往操作
       apis.MenuAllData(row.Id).then(res=>{
         console.log(res);
              this.$store.commit("SaveId", row.Id);
              this.$store.commit("Guid", row.DataGuid);
              this.$store.commit("treedata", row.Id)  // 保存树节点Id
              this.$store.commit("pmiddata", row.Id)
              this.$store.commit("nodekeydata", row.Id);
         this.arrx=res.data.ExtraData.split(",")
          if(this.arrx[0]==1&&this.arrx[1]==1&&this.arrx[2]==1){
              this.$store.commit("menustate", 3);
              this.$router.push({name:"ScientificResearchProcess"})
              return
            }
            if(this.arrx[0]==1){
              this.$store.commit("menustate", 2);
              this.$router.push({name:"StatisticalOverview"})
              return
            }
            if(this.arrx[1]==1){
              this.$store.commit("menustate", 3);
              this.$router.push({name:"ScientificResearchProcess"})
              return
            }
            if(this.arrx[2]==1){
              this.$store.commit("menustate", 4);
              this.$router.push({name:"ChartOfAccounts"})
              return
            }
     })
    },
    Scroll(e) {
      // console.log(this.$refs.evabox.scrollTop)
      const ele = e.srcElement ? e.srcElement : e.target;
      // 监听滚动到div底部
      if (ele.scrollTop + ele.offsetHeight > ele.scrollHeight - 2) {
        if (this.flag == false) {
          console.log(11);
          this.flag = true;
          this.Stopiclist();
          //    setTimeout( ()=>{
          //   this.flag=false
          //  },2000)
        }
      }
    },
    sendoutmsglist() {
      //查看批量发送消息通知数据
      let parame = {
        TopicId: "",
        PageIndex: 0,
        PageSize: 9,
        SysFlag:-1
      };
      research.querymsg(parame).then((res) => {
      // api.sendoutmsgData(parame).then((res) => {
        console.log(res);
        if (res.data.Status == 1) {
          this.msglist = res.data.Entity;
        } else {
          alert(res.data.Message);
        }
      });
    },
    checkProjectOverviewlist() {
      //项目概览数据
      let parame = window.sessionStorage.getItem("Id");
      api.ProjectOverviewData(parame).then((res) => {
        if (res.data.Status == 1) {
            console.log(res,"项目概览数据");
          this.obj = res.data.Entity;
          this.$nextTick(() => {
            this.CompleteTheDashboardA();
            this.CompleteTheDashboardB();
          });
        } else {
          alert(res.data.Message);
        }
      });
    },
    CompleteTheDashboardA() {
      var chartDom = document.getElementById("mainA");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        // backgroundColor: 'rgb(0,17,76)',
        title: [
          {
            text: "项目延期率",
            bottom: "130",
            x: "320",
            textStyle: {
              fontWeight: "normal",
              fontSize: 14,
              color: "#707070",
            },
          },
        ],
        angleAxis: {
          show: false,
          max: (100 * 360) / 180, //-45度到225度，二者偏移值是270度除360度
          type: "value",
          startAngle: 180, //极坐标初始角度
          splitLine: {
            show: false,
          },
        },
        barMaxWidth: 24, //圆环宽度
        radiusAxis: {
          show: false,
          type: "category",
        },
        //圆环位置和大小
        polar: {
          center: ["60%", "77%"],
          radius: "350",
        },
        series: [
          {
            type: "bar",
            data: [
              {
                //上层圆环，显示数据
                value: parseInt(this.obj.delayedPro),
                itemStyle: {
                  color: "#00fdf6",
                },
              },
            ],
            barGap: "100%", //柱间距离,上下两层圆环重合
            coordinateSystem: "polar",
            roundCap: true, //顶端圆角从 v4.5.0 开始支持
            z: 2, //圆环层级，同zindex
          },
          {
            //下层圆环，显示最大值
            type: "bar",
            data: [
              {
                value: 100,
                itemStyle: {
                  color: "#e2effd",
                },
              },
            ],
            barGap: "-90%",
            barWidth: 10,
            coordinateSystem: "polar",
            roundCap: true,
            z: 1,
          },
          //仪表盘
          {
            name: "AQI",
            type: "gauge",
            startAngle: 225, //起始角度，同极坐标
            endAngle: -45, //终止角度，同极坐标
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            title: {
              offsetCenter: [24, 0],
              color: "#00CAFD",
              fontSize: 60,
              fontWeight: 500,
              rich: {
                a: {
                  fontWeight: "normal",
                  fontSize: 16,
                  color: "#FFF",
                  padding: [0, 0, 10, 40],
                },
              },
            },
            detail: {
              formatter: function (e) {
                  console.log(e,"eeeeeeeee");
                return "%";
              },
              color: "#00CAFD",
              fontSize: 30,
              offsetCenter: [116, 50],
              padding: [4, 12],
            },
            data: [
              {
                value: 75,
                name:
                  "{a|" + "" + "}" + "\n\n   " + parseInt(this.obj.delayedPro),
              },
            ],
          },
        ],
      };

      option && myChart.setOption(option);
    },
    CompleteTheDashboardB() {
      var chartDom = document.getElementById("mainB");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        // backgroundColor: 'rgb(0,17,76)',
        title: [
          {
            text: "项目完成度",
            bottom: "130",
            x: "220",
            textStyle: {
              fontWeight: "normal",
              fontSize: 14,
              color: "#707070",
            },
          },
        ],
        angleAxis: {
          show: false,
          max: (100 * 360) / 180, //-45度到225度，二者偏移值是270度除360度
          type: "value",
          startAngle: 180, //极坐标初始角度
          splitLine: {
            show: false,
          },
        },
        barMaxWidth: 24, //圆环宽度
        radiusAxis: {
          show: false,
          type: "category",
        },
        //圆环位置和大小
        polar: {
          center: ["42%", "77%"],
          radius: "350",
        },
        series: [
          {
            type: "bar",
            data: [
              {
                //上层圆环，显示数据
                value: parseInt(this.obj.finishPro),
                itemStyle: {
                  color: "#f96ca9",
                },
              },
            ],
            barGap: "100%", //柱间距离,上下两层圆环重合
            coordinateSystem: "polar",
            roundCap: true, //顶端圆角从 v4.5.0 开始支持
            z: 2, //圆环层级，同zindex
          },
          {
            //下层圆环，显示最大值
            type: "bar",
            data: [
              {
                value: 100,
                itemStyle: {
                  color: "#ffe9e9",
                },
              },
            ],
            barGap: "-90%",
            barWidth: 10,
            coordinateSystem: "polar",
            roundCap: true,
            z: 1,
          },
          //仪表盘
          {
            name: "AQI",
            type: "gauge",
            startAngle: 225, //起始角度，同极坐标
            endAngle: -45, //终止角度，同极坐标
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            title: {
              offsetCenter: [-80, 0],
              color: "#f96ca9",
              fontSize: 60,
              fontWeight: 500,
              rich: {
                a: {
                  fontWeight: "normal",
                  fontSize: 16,
                  color: "#FFF",
                  padding: [0, 0, 10, 40],
                },
              },
            },
            detail: {
              formatter: function (e) {
                return "%";
              },
              color: "#f96ca9",
              fontSize: 30,
              offsetCenter: [10, 50],
              padding: [4, 12],
            },
            data: [
              {
                // value: 75,
                name:
                  "{a|" + "" + "}" + "\n\n   " + parseInt(this.obj.finishPro),
              },
            ],
          },
        ],
      };

      option && myChart.setOption(option);
    },
    CompleteTheDashboardC() {
      console.log(this.list,"%%%%%");
      var chartDom = document.getElementById("mainC");
      var myChart = echarts.init(chartDom);
      var option;
      const colorList = [
        "#47A2FF ",
        "#53C8D1",
        "#59CB74",
        "#FBD444",
        "#7F6AAD",
        "#585247",
      ];
      var sty = this.list;

      option = {
        title: {
          // text: this.obj.allNum,
          text: this.statisticaNum,
          subtext: "项目总数",
          textStyle: {
            fontSize: 42,
            color: "#319EFD",
            lineHeight: 20,
          },
          subtextStyle: {
            fontSize: 16,
            color: "#333",
          },
          textAlign: "center",
          left: "28%",
          top: "45%",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: "10%",
          top: "center",
          itemGap: 20,
          selectedMode: false,
          icon: "pin",
          // data: this.list,
          textStyle: {
            color: "#77899c",
            rich: {
              a: {
                width: 90,
                color: "#66666",
                fontSize: 16,
              },
              b: {
                width: 50,
                fontSize: 16,
                color: "#3388FF",
              },
              c: {
                fontSize: 16,
                color: "#3388FF",
              },
            },
          },
          formatter(name) {
            var value = 0;
            // var percent = 0; //占比
            for (var i = 0; i < sty.length; i++) {
              if (name == sty[i].name) {
                value = sty[i].value;
                // percent = sty[i].percent;
              }
            }
            // var arr= [`{a|${name}}{b|${value}}{c|${percent.toFixed(2)}%}`]
            var arr= [`{a|${name}}{b|${value}}`]
            return arr.join("");
          },
        },
        color: colorList,
        series: [
          {
            // name: '姓名',
            type: "pie",
            radius: [90, 120],
            center: ["28%", "50%"],
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              borderWidth: 3,
              borderColor: "#fff",
            },
            data: this.list,
          },
        ],
      };

      option && myChart.setOption(option);
    },
    Completionlist() {
      //阶段统计数据
      let parame = window.sessionStorage.getItem("Id");
      api.CompletionData(parame).then((res) => {
        if (res.data.Status == 1) {
          console.log(res, "阶段统计数据");
          let statsNum = 0
          this.list = res.data.Entity.map((item, index,arr) => {
            statsNum += item.num
            return {
              value: item.num,
              name: item.flowName,
              // percent:(item.num/(arr.length))*100
            };
          });
          this.statisticaNum = statsNum
          console.log(this.list,"阶段统计数据");
          this.$nextTick(() => {
            this.CompleteTheDashboardC();
          });
        }
      });
    },
    Stopiclist() {
      //项目列表
      if (this.i <= this.TotalPages) {
        let parame = {
          UserId: sessionStorage.getItem("Id"),
          FlowName: "",
          PageIndex: this.i,
          PageSize: 2,
        };
        api.StopicData(parame).then((res) => {
          console.log(res, "项目");
          var arr = res.data.Entity.map((item) => {
            api
              .StagesData({ TopicId: item.Id, PageIndex: 0, PageSize: 6 })
              .then((res) => {
                this.$set(item, "list", res.data.Entity);
              });
            item["active"] = 0;
            item["sop"] = 0;
            let a1 = item.flowNameJoint.split(",");
            a1.pop();
            let list = [];
            a1.forEach((k) => {
              let a2 = k.split("|");
              list.push({
                text: a2[0],
                num: a2[1],
              });
            });
            item.flowNameJoint = list.map((j, index, arr) => {
              if (j.num == 3) {
                item["active"] = index + 1;
                item["sop"] = parseInt((100 / arr.length) * (index + 1));
              }
              return j;
            });
            return item;
          });
          this.projectlist = this.projectlist.concat(arr);
          this.i++;
          this.flag = false;
          this.TotalPages = res.data.TotalPages - 1;
          console.log(this.projectlist, "项目数组");
        });
      } else {
        this.$message({
          message: "没有更多内容了",
          type: "success",
        });
      }
    },
    alltopic() {
      //查看全部
      this.$store.commit("menustate", 7);
      this.$router.push({ name: "ProjectManagementPage" });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/mixin.scss";
.p1 {
  @include add-size($font_size_16);
}
.HomePage {
  width: 1920px;
  height: 100%;
  margin: 0 auto;
  .HomePage_herd {
    width: 1920px;
    height: 80px;
  }
  .div1{
      width: 100%;
      height: 30px;
      background: #f0f7fd;
      img{
        width: 30px;
        height: 30px;
        margin-left: 33%;
      }
    }
  .HomePage_body {
    width: 1920px;
    height: calc(100% - 110px);
    // padding: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    // padding-top: 30px;
    background: #f0f7fd;
    box-sizing: border-box;
    .HomePage_body1 {
      width: 1220px;
      height: 100%;
      margin-right: 20px;
      float: left;
      .HomePage_body1_lower {
        width: 1220px;
        height: 470px;
        background: #ffffff;
        border-radius: 10px;
        margin-bottom: 21px;
        transition: height 1s;
        .HomePage_body1_h2 {
          width: 1220px;
          height: 52px;
          // background: olive;
          overflow: hidden;
          p:nth-child(1) {
            margin-top: 10px;
            float: left;
            span:nth-child(1) {
              display: inline-block;
              width: 3px;
              height: 14px;
              background: #3388ff;
              margin-left: 21px;
              margin-right: 14px;
            }
            span:nth-child(2) {
              @include add-size1($font_size_20);
              font-weight: 500;
              color: #333333;
            }
          }
          p:nth-child(2) {
            width: 120px;
            float: right;
            height: 32px;
            margin-top: 10px;
            span:nth-child(1) {
              color: #3388ff;
              font-weight: 400;
              @include add-size($font_size_16);
              //margin-left: 20px;
              margin-right: 10px;
            }
            span:nth-child(2) {
              font-weight: 400;
              color: #3388ff;
              font-size: 24px;
            }
          }
        }
        .HomePage_body1_div1 {
          width: 1220px;
          height: 258px;
          .div1 {
            width: 610px;
            height: 258px;
            background: url("../assets/image/tuxing1.png") no-repeat;
            background-size: 320px 170px;
            background-position: 206px 35px;
            float: left;
            #mainA {
              width: 610px;
              height: 258px;
            }
          }
          .div2 {
            width: 610px;
            height: 258px;
            background: url("../assets/image/tuxing2.png") no-repeat;
            background-size: 320px 170px;
            background-position: 98px 35px;
            float: left;
            #mainB {
              width: 610px;
              height: 258px;
            }
          }
        }
        .HomePage_body1_div2 {
          width: 1220px;
          height: 160px;
          // background: orangered;
          ul {
            width: 1220px;
            height: 160px;
            li:nth-child(1) {
              width: 220px;
              height: 130px;
              background: orchid;
              margin-left: 74px;
              margin-right: 64px;
              float: left;
              border-radius: 4px;
              background: url("../assets/image/qw1.png") 100% top no-repeat,
                linear-gradient(35deg, #3388ff 0%, #4d90f1 100%);
              h2 {
                text-align: center;
                span {
                  display: inline-block;
                  margin-top: 30px;
                  @include add-size5($font_size_14);
                  color: #fff;
                  margin-bottom: 10px;
                }
              }
              p {
                text-align: center;
                span {
                  @include add-size3($font_size_34);
                  color: #fff;
                  display: inline-block;
                }
              }
            }
            li:nth-child(2) {
              width: 220px;
              height: 130px;
              background: orchid;
              margin-right: 61px;
              border-radius: 4px;
              float: left;
              background: url("../assets/image/qw2.png") 100% top no-repeat,
                linear-gradient(35deg, #2ed9df 0%, #3ceef4 100%);
              h2 {
                text-align: center;
                span {
                  display: inline-block;
                  margin-top: 30px;
                  @include add-size5($font_size_14);
                  color: #fff;
                  margin-bottom: 10px;
                }
              }
              p {
                text-align: center;
                span {
                  @include add-size3($font_size_34);
                  color: #fff;
                  display: inline-block;
                }
              }
            }
            li:nth-child(3) {
              width: 220px;
              height: 130px;
              background: orchid;
              border-radius: 4px;
              margin-right: 64px;
              float: left;
              background: url("../assets/image/qw3.png") 100% top no-repeat,
                linear-gradient(35deg, #ff9700 0%, #ffc000 100%);
              h2 {
                text-align: center;
                span {
                  display: inline-block;
                  margin-top: 30px;
                  @include add-size5($font_size_14);
                  color: #fff;
                  margin-bottom: 10px;
                }
              }
              p {
                text-align: center;
                span {
                  @include add-size3($font_size_34);
                  color: #fff;
                  display: inline-block;
                }
              }
            }
            li:nth-child(4) {
              width: 220px;
              height: 130px;
              background: orchid;
              border-radius: 4px;
              float: left;
              background: url("../assets/image/qw4.png") 100% top no-repeat,
                linear-gradient(35deg, #ff717c 0%, #ff719f 100%);
              h2 {
                text-align: center;
                span {
                  display: inline-block;
                  margin-top: 30px;
                  @include add-size5($font_size_14);
                  color: #fff;
                  margin-bottom: 10px;
                }
              }
              p {
                text-align: center;
                span {
                  @include add-size3($font_size_34);
                  color: #fff;
                  display: inline-block;
                }
              }
            }
          }
        }
      }
      .HomePage_body1_lower1{
        height: 0;
      }
      .HomePage_body1_upper {
        width: 1220px;
        height: calc(100% - 491px);
        overflow: auto;
        border-radius: 10px;
        background: #fff;
        transition: height 1s;
        h2 {
          width: 1210px;
          height: 56px;
          overflow: hidden;
          p:nth-child(1) {
            width: 1000px;
            height: 46px;
            float: left;
            margin-top: 10px;
            span:nth-child(1) {
              display: inline-block;
              width: 3px;
              height: 14px;
              background: #3388ff;
              border-radius: 38px;
              margin-right: 20px;
              margin-left: 22px;
            }
            span:nth-child(2) {
              @include add-size1($font_size_20);
              font-weight: 500;
              color: #333333;
            }
          }
          p:nth-child(2):hover {
            cursor:pointer
          }
          p:nth-child(2) {
            width: 118px;
            height: 46px;
            float: right;
            margin-top: 10px;
            span:nth-child(1) {
              display: inline-block;
              color: #3388ff;
              font-weight: 400;
              @include add-size($font_size_16);
              vertical-align: middle;
            }
            span:nth-child(2) {
              font-weight: 400;
              color: #3388ff;
              vertical-align: middle;
              margin-left: 10px;
            }
          }
        }
        .div4 {
          width: 1210px;
          height: 393px;
          .div5 {
            width: 1157px;
            height: 76px;
            background: #f0f7fd;
            border-radius: 9px;
            margin-left: 34px;
            margin-bottom: 36px;
            h3 {
              width: 1000px;
              height: 76px;
              float: left;
              p:nth-child(1) {
                margin-top: 6px;
                img {
                  width: 20px;
                  height: 20px;
                  vertical-align: middle;
                  margin-left: 20px;
                  margin-right: 4px;
                }
                span:nth-child(2) {
                  // width: 80%;
                  color: #585858;
                  display: inline-block;
                  vertical-align: middle;
                  @include add-size4($font_size_18);
                  margin-right: 4px;
                }
                span:nth-child(3) {
                  text-align: center;
                  width: 70px;
                  line-height: 24px;
                  display: inline-block;
                  vertical-align: middle;
                  background: #ff9900;
                  color: #fff;
                  border-radius: 4px;
                  @include add-size5($font_size_14);
                  margin-left: 10px;
                  //a {
                  //  display: inline-block;
                  //  margin-left: 4px;
                  //  margin-right: 4px;
                  //  color: #fff;
                  //  vertical-align: middle;
                  //  margin-bottom: 6px;
                  //  @include add-size5($font_size_14);
                  //}
                }
              }
              p:nth-child(2) {
                img {
                  width: 20px;
                  height: 20px;
                  vertical-align: middle;
                  margin-left: 20px;
                  margin-right: 4px;
                }
                span:nth-child(2) {
                  display: inline-block;
                  @include add-size($font_size_16);
                  color: #666666;
                  vertical-align: middle;
                  font-weight: 400;
                }
                span:nth-child(4) {
                  display: inline-block;
                  @include add-size($font_size_16);
                  color: #666666;
                  font-weight: 400;
                  vertical-align: middle;
                }
              }
            }
            h4 {
              width: 157px;
              height: 76px;
              float: left;
              overflow: hidden;
              button {
                width: 120px;
                height: 36px;
                border: 1px solid #3388ff;
                border-radius: 138px;
                color: #333333;
                background: 0;
                margin-top: 20px;
                margin-left: 16px;
                @include add-size($font_size_16);
              }
            }
          }
          .div6 {
            width: 1157px;
            height: 317px;
            // background: orangered;
            margin-left: 34px;
            .div6_left {
              width: 200px;
              height: 200px;
              // background: orchid;
              float: left;
              background: url("../assets/image/yuan.png") no-repeat;
              background-size: 146px 146px;
              background-position: 27px 27px;
              position: relative;
              p {
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                position: absolute;
                top: 120px;
                left: 70px;
              }
              /deep/.el-progress__text {
                font-size: 38px !important;
                color: #026dfc;
              }
            }
            .div6_right {
              width: 927px;
              height: 230px;
              float: left;
              margin-left: 30px;
              .div6_right_1 {
                width: 927px;
                height: 90px;
                /deep/.el-step__icon {
                  width: 48px;
                  height: 48px;
                }
                /deep/.el-step.is-horizontal .el-step__line {
                  top: 21px;
                }
                /deep/.el-step__icon-inner {
                  font-size: 20px;
                }
                /deep/.el-step__title.is-success {
                  color: #319efd;
                }
                /deep/.el-step__head.is-success {
                  color: #319efd;
                  border-color: #319efd;
                }
              }
              .div6_right_2 {
                // width: 927px;
                width: 820px;
                height: 140px;
                margin: 0 auto;
                p {
                  margin-top: 10px;
                  width: 400px;
                  float: left;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  color: #585858;
                  span:nth-child(1) {
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #31acfa;
                    margin-right: 6px;
                  }
                  span:nth-child(2) {
                    @include add-size($font_size_16);
                  }
                }
              }
            }
          }
        }
      }
      .HomePage_body1_upper1{
       height: 100%;
      }
    }
    .HomePage_body2 {
      width: 620px;
      height: 100%;
      float: left;
      .HomePage_body2_lower {
        width: 620px;
        height: calc(100% - 382px);
        border-radius: 10px;
        //margin-bottom: 20px;
        background: #fff;
        overflow: auto;
        h3 {
          width: 610px;
          height: 42px;
          p:nth-child(1) {
            width: 500px;
            float: left;
            height: 32px;
            margin-top: 10px;
            span:nth-child(1) {
              display: inline-block;
              width: 3px;
              height: 14px;
              background: #3388ff;
              border-radius: 38px;
              margin-left: 20px;
              margin-right: 20px;
            }
            span:nth-child(2) {
              @include add-size1($font_size_20);
              font-weight: 500;
              color: #333333;
            }
          }
          p:nth-child(2):hover {
            cursor:pointer
          }
          p:nth-child(2) {
            width: 100px;
            float: left;
            height: 32px;
            margin-top: 10px;
            span:nth-child(1) {
              color: #3388ff;
              font-weight: 400;
              @include add-size($font_size_16);
              //margin-left: 20px;
              margin-right: 10px;
            }
            span:nth-child(2) {
              font-weight: 400;
              color: #3388ff;
              font-size: 24px;
            }
          }
        }
        .div7 {
          width: 610px;
          height: 400px;
          ul {
            height: 100%;
            overflow: hidden;
            li {
              margin-left: 30px;
              margin-top: 22px;
              img {
                width: 20px;
                height: 20px;
                vertical-align: middle;
                margin-right: 20px;
              }
              span:nth-child(2) {
                width: 100px;
                display: inline-block;
                text-align: left;
                vertical-align: middle;
                margin-right: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                @include add-size($font_size_16);
                color: #585858;
                font-weight: 400;
              }
              span:nth-child(3) {
                display: inline-block;
                width: 230px;
                vertical-align: middle;
                margin-right: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                @include add-size($font_size_16);
                color: #585858;
                font-weight: 400;
              }
              span:nth-child(4) {
                width: 170px;
                display: inline-block;
                vertical-align: middle;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                @include add-size($font_size_16);
                color: #585858;
                font-weight: 400;
              }
            }
          }
        }
      }
      .HomePage_body2_upper {
        width: 620px;
        height: 362px;
        background: #ffffff;
        border-radius: 10px;
        margin-top: 20px;
        overflow: hidden;
        h3 {
          width: 620px;
          height: 42px;
          overflow: hidden;
          p:nth-child(1) {
            width: 500px;
            float: left;
            height: 32px;
            margin-top: 10px;
            span:nth-child(1) {
              display: inline-block;
              width: 3px;
              height: 14px;
              background: #3388ff;
              border-radius: 38px;
              margin-left: 20px;
              margin-right: 20px;
            }
            span:nth-child(2) {
              @include add-size1($font_size_20);
              font-weight: 500;
              color: #333333;
            }
          }
          p:nth-child(2):hover {
            cursor:pointer
          }
          p:nth-child(2) {
            width: 120px;
            float: left;
            height: 32px;
            margin-top: 10px;
            span:nth-child(1) {
              color: #3388ff;
              font-weight: 400;
              @include add-size($font_size_16);
              //margin-left: 20px;
              margin-right: 10px;
            }
            span:nth-child(2) {
              font-size: 24px;
              font-weight: 400;
              color: #3388ff;
              //vertical-align: 2px;
            }
          }
        }
        .div3 {
          width: 620px;
          height: 310px;
          // background: orange;
          #mainC {
            width: 620px;
            height: 310px;
          }
        }
      }
    }
  }
}
.notice-list {
  /deep/ .el-dialog {
    width: 903px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      margin-top: 20px;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
  }
}
.notice-details {
  /deep/ .el-dialog {
    width: 578px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      margin-top: 20px;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .el-form {
      .el-form-item {
        .el-form-item__label {
          @include add-size($font_size_16);
          color: #666666;
        }
        .el-input__inner {
          border: 0;
        }
        .el-textarea__inner {
          border: 0;
          resize: none;
        }
      }
    }
  }
}
::-webkit-scrollbar {
  width: 10px; /*对垂直流动条有效*/
  height: 10px; /*对水平流动条有效*/
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 3px;
}

/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: #1677ff;
}
.block {
  margin-top: 10px;
}
.notice-details  /deep/.el-input {
  @include add-size($font_size_16);
  border: 0;
}
.notice-details  /deep/.el-textarea {
  @include add-size($font_size_16);
  border: 0;
}
.notice-list /deep/.el-table th {
  @include add-size($font_size_16);
  vertical-align: middle;
}
.notice-list /deep/.el-table__row {
  @include add-size($font_size_16);
  vertical-align: middle;
}
</style>
